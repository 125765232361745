.admin {
  width: 100%;
  height: 100vh;
  background-color: #102a3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 10px;
  overflow: hidden;
}

.admin img {
  margin: 0 auto 2rem auto;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 500px) {
  .admin img {
    max-width: 100%;
    object-fit: cover;
  }
}

.admin h5 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 436px) {
  .admin h5 {
    font-size: 30px;
  }
}

@media (max-width: 352px) {
  .admin h5 {
    font-size: 22px;
  }
}

@media (max-width: 284px) {
  .admin h5 {
    font-size: 20px;
  }
}


.admin .fa-fish, .admin .fa-bread-slice {
  margin: 0 20px;
}

.admin form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.admin form label {
  font-size: 20px;
  margin-top: 1rem;
}

.admin input {
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  outline: none;
  margin-top: .5rem;
  border: 0;
  transition: 250ms ease-in-out;
}

.admin input:focus {
  box-shadow: 0px 0px 4px 2px #40fe9f;
  -webkit-box-shadow: 0px 0px 4px 2px #40fe9f;
}

.admin button {
  border: none;
  position: relative;
  z-index: 0;
  width: 150px;
  height: 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  outline: none;
  color: #fff;
  background-color: #102a3c;
  border: 4px solid #40fe9f;
  margin: 3rem auto 1rem auto;
  cursor: pointer;
  letter-spacing: 3px;
  transition: 250ms ease-in-out;
}

.admin button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.admin button:active {
    color: #000
}

.admin button:active:after {
    background: transparent;
}

.admin button:hover:before {
    opacity: 1;
}

.admin button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}