@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}
* { padding:0; margin:0; outline:0; }

h1, h2, h3, h4, h5, h6, ul, ol, p {
  margin: 0;
}

input, textarea, select { font-family: Arial, sans-serif; font-size:11px; }
a img { border:0; }
a {text-decoration: none; cursor:pointer; }
.cl { font-size:0; line-height:0; clear:both; display:block; height:0; }

html {
  scroll-behavior: smooth;
}

body {
  padding:0;
  margin:0;
  outline:0;
  font-family: 'Nunito', sans-serif;
}
header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 1;
  transition: 250ms ease-in-out;
}

.header {
  background-color: #194664; 
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.8);
}

.header2 {
  background-color: transparent;
}

header .logo {
  width: 220px;
  height: auto;
}

header nav ul {
  list-style: none;
}

header nav ul li {
  display: inline-block;
  margin: 0 1rem;
}

header nav ul li a {
  color: #fff;
  font-size: 20px;
  padding-bottom: 5px;
  border-bottom: 0px solid transparent;
  transition: 100ms ease-in-out;
}

@media(max-width: 900px) {
  header nav ul li a {
    font-size: 18px;
  }
}

header nav ul li a:hover {
  border-bottom: 2px solid #40fe9f;
}

header .social a {
  margin: 0 5px;
}

header .social .fab {
  color: #fff;
  font-size: 32px;
  transition: 250ms ease-in-out;
}

header .social .fab:hover {
  color: #40fe9f;
  font-size: 32px;
}

header .bars {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: none;
}

header nav .nav-social {
  display: none;
}

@media (max-width: 850px) {
  header nav {
    display: none;
  }

  header .social {
    display: none;
  }

  header .bars {
    display: block;
  }
}
.dropdown img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.dropdown .dropdown-icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  color: #fff;
}

.dropdown .dropdown-wrapper .dropdown-menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 80px);
  text-align: center;
  margin-bottom: 4rem;
}

.dropdown .dropdown-wrapper .dropdown-menu a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.dropdown .dropdown-wrapper .dropdown-social {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
} 

.dropdown .dropdown-wrapper .dropdown-social a {
  color: #fff;
  font-size: 38px;
  margin: 0 10px;
}
.hero {
  width: 100%;
  height: 100vh;
  background-image: url(https://ik.imagekit.io/obelussoft/hero_0cbTeyiHy.png?updatedAt=1640108522382);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  overflow: hidden;
}

.hero .hero-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.hero .hero-wrapper h2 {
  color: #fff;
  font-size: 44px;
  font-weight: 800;
}

.hero .hero-wrapper h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}

.hero .arrow-down {
  position: absolute;
  bottom: 5%;
  left: 50%;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.hero .arrow-down:hover {
  color: #40fe9f;
}
.about {
  width: 100%;
  height: auto;
  padding: 10rem 10px;
  background-color: #102a3c;
  overflow: hidden;
}

.about .about-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.about .about-wrapper h3 {
  width: 400px;
  text-align: center;
  margin: 0 auto 1rem auto;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
}

@media (max-width: 420px) {
  .about .about-wrapper h3 {
    font-size: 26px;
    width: 300px;
  }
}

.about .about-wrapper p {
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
  color: #fff;
}
.events {
  width: 100%;
  height: auto;
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(https://ik.imagekit.io/obelussoft/events-bg_F26QMzBgFjt.png?updatedAt=1640108554209);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 20px;
  overflow: hidden;
}

.events .events-wrapper {
  max-width: 1280px;
  width: 100%;
  color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 520px) {
  .events .events-wrapper img {
    object-fit: contain;
    max-width: 100%;
  }
}

.events .events-wrapper h4 {
  color: #40fe9f;
  font-size: 26px;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
  font-weight: 800;
  margin: 1rem;
}

.events .events-wrapper p {
  font-size: 20px;
  margin: .5rem 0;
}

@media (max-width: 780px) {
  .events .events-wrapper p {
    font-size: 16px;
  }
}

.events .events-wrapper .event-place {
  color: #27aae1;
  font-weight: 800;
}

.events .events-wrapper .event-info-p {
  text-align: justify;
}

.events .events-wrapper .event-center {
  text-align: center;
}

.events .events-wrapper button {
  width: 230px;
  height: 50px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  outline: none;
  color: #fff;
  background-color: #102a3c;
  border: 4px solid #40fe9f;
  margin: 3rem 0 1rem 0;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.events .events-wrapper button:hover {
  transform: scale(1.2);
}

.events .events-wrapper .event-sponsors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 3rem;
}

.events .events-wrapper .event-sponsors img {
  width: 200px;
  height: auto;
}

@media (max-width: 1100px) {
  .events .events-wrapper .event-sponsors img {
    width: 150px;
  }
}

@media (max-width: 535px) {
  .events .events-wrapper .event-sponsors img {
    width: 100px;
  }
}
.team {
  width: 100%;
  height: auto;
  background-color: #102a3c;
  padding: 4rem 10px;
  overflow: hidden;
}

.team .team-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.team .team-wrapper h4 {
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: 0 auto 1rem auto;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
}

.team .team-wrapper .subteam-heading {
  width: 400px;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  font-size: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  margin: 2rem 0 1rem 4rem;
}

@media (max-width: 750px) {
  .team .team-wrapper .subteam-heading {
    width: 90%;
    margin: 2rem auto 1rem auto;
    text-align: center;
  }
}

.team .team-wrapper  button {
  width: 230px;
  height: 50px;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  outline: none;
  color: #fff;
  background-color: #102a3c;
  border: 4px solid #40fe9f;
  margin: 3rem 0 1rem 0;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.team .team-wrapper  button:hover {
  transform: scale(1.2);
}


.main-team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.left-align {
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 5rem;
}

@media (max-width: 750px) {
  .left-align {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}

.main-team .card {
  margin: 1rem;
}

.main-team .card img {
  width: auto;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
}

@media (max-width: 375px) {
  .main-team .card img {
    height: 290px;
  }
}

.main-team .card .small-img {
  height: 250px;
}

.main-team .card h5 {
  font-size: 22px;
  margin-left: 1rem;
  margin-top: .5rem;
  color: #fff;
}

.main-team .card p {
  font-size: 18px;
  margin-left: 1rem;
  color: #fff;
}

.main-team .small-name {
  text-align: center;
  margin: .5rem auto 0 auto !important;
}
.main-team .small-description {
  text-align: center;
}

.main-team-two {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 950px) {
  .main-team-two {
    flex-direction: column;
  }
}
footer {
  width: 100%;
  height: auto;
  overflow: hidden;
}

footer .footer-up {
  background-color: #0b1b27;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding: 2rem 10px;
}

@media (max-width: 960px) {
  footer .footer-up {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

footer .footer-up .footer-div {
  display: flex;
  flex-direction: column;
}

@media (max-width: 960px) {
  footer .footer-up .footer-div {
    margin: 1rem 0;
    justify-content: center;
    align-items: center;
  }
}

.footer-div h6 {
  width: 250px;
  font-size: 26px;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 1rem;
}

@media (max-width: 960px) {
  .footer-div h6 {
    text-align: center;
  }
}

.footer-div img {
  width: 350px;
  height: auto;
  margin-bottom: 1rem;
}

.footer-div p {
  width: 400px;
  color: #fff;
}

@media (max-width: 960px) {
  .footer-div img {
    width: 250px;
    height: auto;
    margin-bottom: 1rem;
  }
  .footer-div p {
    width: 250px;
    color: #fff;
    text-align: justify;
  }
}

.footer-div ul {
  list-style: none;
}

.footer-div ul li {
  margin: 1rem 0;
}

.footer-div ul li a {
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
  transition: 200ms ease-in-out;
}

.footer-div ul li a:hover {
  border-bottom: 2px solid #40fe9f;
}

.footer-div .fab {
  color: #fff;
  font-size: 40px;
  margin: .5rem 0;
  transition: 200ms ease-in-out;
}

.footer-div .fab:hover {
  color: #40fe9f;
}

footer .footer-down {
  width: 100%;
  background-color: #000;
  padding: .5rem 10px;
  overflow: hidden;
}

footer .footer-down p {
  color: #fff;
  text-align: center;
  font-size: 18px;
}

footer .footer-down a {
  color: #fff;
}

footer .footer-down a:hover {
  color: #40fe9f;
}

footer .arrow-top {
  font-size: 30px;
  color: #fff;
  position: fixed;
  bottom: 3%;
  right: 2%;
  background-color: #40fe9f;
  border-radius: 50%;
  padding: .5rem;
  transition: 250ms ease-in-out;
}

footer .arrow-top:hover {
  color: #40fe9f;
  background-color: #fff;
}

footer .show {
  display: block;
}

footer .show {
  display: none;
}
.team {
    width: 100%;
    height: auto;
    background-color: #102a3c;
    padding: 4rem 10px;
    overflow: hidden;
  }
  
  .team .team-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .team .team-wrapper h4 {
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 0 auto 1rem auto;
    padding-top: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
  }
  
  .team .team-wrapper .subteam-heading {
    width: 400px;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    font-size: 24px;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    margin: 2rem 0 1rem 4rem;
  }
  
  @media (max-width: 750px) {
    .team .team-wrapper .subteam-heading {
      width: 90%;
      margin: 2rem auto 1rem auto;
      text-align: center;
    }
  }
  
  .main-team {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .left-align {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5rem;
  }
  
  @media (max-width: 750px) {
    .left-align {
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
  }
  
  .main-team .card {
    margin: 1rem;
  }
  
  .main-team .card img {
    width: auto;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
  }
  
  @media (max-width: 375px) {
    .main-team .card img {
      height: 290px;
    }
  }
  
  .main-team .card .small-img {
    height: 250px;
  }
  
  .main-team .card h5 {
    font-size: 22px;
    margin-left: 1rem;
    margin-top: .5rem;
    color: #fff;
  }
  
  .main-team .card p {
    font-size: 18px;
    margin-left: 1rem;
    color: #fff;
  }

  
  .main-team .small-name {
    text-align: center;
    margin: .5rem auto 0 auto !important;
  }

  .main-team .small-description {
    text-align: center;
  }
  
  .main-team-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  @media (max-width: 950px) {
    .main-team-two {
      flex-direction: column;
    }
  }
.faq {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #102a3c;
  color: #fff;
}

/* FAQ Paralax Image */
.faq .faq-bg {
  width: 100%;
  height: 60vh;
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(/static/media/events-bg.e210ae9a.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.faq .faq-bg img {
  width: 400px;
  height: auto;
}

.faq .faq-bg h2 {
  font-size: 34px;
  color: #fff;
  margin: 1rem 0 2rem 0;
  border-bottom: 2px solid #40fe9f;
  padding-bottom: 10px;
  text-align: center;
}

/* FAQ Wrapper */
.faq .faq-wrapper {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 10px;
  overflow: hidden;
}

.faq .faq-wrapper p {
  font-size: 20px;
  text-align: center;
  margin: .5rem 0;
}

.faq-wrapper .faq-details {
  width: 100%;
  margin: 2rem 0;
}

.faq-wrapper .faq-details details {
  width: 100%;
}

.faq-wrapper .faq-details summary {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: #fff;
  color: #333;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}

@media (max-width: 550px) {
  .faq-wrapper .faq-details summary {
    font-size: 1rem;
  }
}

.faq .faq-wrapper .faq-content p {
  text-align: justify;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

@media (max-width: 800px) {
  .faq .faq-wrapper .faq-content p {
    font-size: 16px;
  }
}

.faq .faq-wrapper .faq-content a {
  color: #40fe9f;
  margin: 0 5px;
  border-bottom: 2px solid transparent;
  transition: 250ms ease-in-out;
}

.faq .faq-wrapper .faq-content a:hover {
  border-bottom: 2px solid #40fe9f;
}

.faq-wrapper .faq-details details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;
}

.faq-wrapper .faq-details details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px;
}

.faq-wrapper .faq-details details > summary::-webkit-details-marker {
  display: none;
}

.faq-wrapper .faq-details details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}

.faq .faq-wrapper .faq-details h3 {
  max-width: 500px;
  width: 100%;
  margin: 4rem 0 1rem 0;
  font-size: 24px;
  border-bottom: 2px solid #40fe9f;
  padding-bottom: 5px;
}
.admin {
  width: 100%;
  height: 100vh;
  background-color: #102a3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 10px;
  overflow: hidden;
}

.admin img {
  margin: 0 auto 2rem auto;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 500px) {
  .admin img {
    max-width: 100%;
    object-fit: cover;
  }
}

.admin h5 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 436px) {
  .admin h5 {
    font-size: 30px;
  }
}

@media (max-width: 352px) {
  .admin h5 {
    font-size: 22px;
  }
}

@media (max-width: 284px) {
  .admin h5 {
    font-size: 20px;
  }
}


.admin .fa-fish, .admin .fa-bread-slice {
  margin: 0 20px;
}

.admin form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.admin form label {
  font-size: 20px;
  margin-top: 1rem;
}

.admin input {
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  outline: none;
  margin-top: .5rem;
  border: 0;
  transition: 250ms ease-in-out;
}

.admin input:focus {
  box-shadow: 0px 0px 4px 2px #40fe9f;
  -webkit-box-shadow: 0px 0px 4px 2px #40fe9f;
}

.admin button {
  border: none;
  position: relative;
  z-index: 0;
  width: 150px;
  height: 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  outline: none;
  color: #fff;
  background-color: #102a3c;
  border: 4px solid #40fe9f;
  margin: 3rem auto 1rem auto;
  cursor: pointer;
  letter-spacing: 3px;
  transition: 250ms ease-in-out;
}

.admin button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.admin button:active {
    color: #000
}

.admin button:active:after {
    background: transparent;
}

.admin button:hover:before {
    opacity: 1;
}

.admin button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
.admin-panel {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(/static/media/events-bg.e210ae9a.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 4rem 10px;
  overflow: hidden;
}

.admin-panel .admin-panel-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.admin-panel-wrapper .admin-panel-links {
  display: flex;
  justify-content: space-between;
}

.admin-panel-wrapper .admin-link {
  color: #fff;
  font-size: 20px;
  transition: 250ms ease-in-out;
  border-bottom: 2px solid transparent;
  max-width: 190px;
  width: 100%;
  cursor: pointer;
}

.admin-panel-wrapper .admin-link:hover {
  color: #40fe9f;
  border-bottom: 2px solid #fff;
}

.admin-panel-wrapper .admin-link-short {
  max-width: 75px;
}

.admin-panel-wrapper img {
  margin: 0 auto 2rem auto;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 500px) {
  .admin-panel-wrapper img {
    max-width: 100%;
    object-fit: cover;
  }
}

.admin-panel-wrapper h5 {
  font-size: 40px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (max-width: 436px) {
  .admin-panel-wrapper h5 {
    font-size: 30px;
  }
}

@media (max-width: 352px) {
  .admin-panel-wrapper h5 {
    font-size: 22px;
  }
}

@media (max-width: 284px) {
  .admin-panel-wrapper h5 {
    font-size: 20px;
  }
}

.admin-panel-wrapper .fas {
  margin: 0 20px;
}

.admin-panel-wrapper h6 {
  max-width: 200px;
  width: 100%;
  font-size: 28px;
  padding-bottom: 5px;
  cursor: pointer;
  margin: 2rem 0 1rem 0;
}

@media (max-width: 380px) {
  .admin-panel-wrapper h6 {
    font-size: 22px;
  }
}

@media (max-width: 290px) {
  .admin-panel-wrapper h6 {
    font-size: 20px;
  }
}

.admin-panel-wrapper .active-h6 {
  border-bottom: 2px solid #40fe9f;
}

.admin-panel-wrapper p {
  font-size: 20px;
  margin: .5rem 0;
}

.admin-panel-wrapper .admin-panel-buttons {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

/* Admin Form */
.admin-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 250ms ease-in-out;
  display: none;
}

.active-form {
  transition: 250ms ease-in-out;
  display: flex;
}

.admin-form label {
  font-size: 20px;
  margin-top: 1rem;
}

.admin-form input {
  height: 40px;
  font-size: 20px;
  padding: 10px;
  outline: none;
  margin-top: .5rem;
  border: 0;
  transition: 250ms ease-in-out;
}

.admin-form input:focus {
  box-shadow: 0px 0px 4px 2px #40fe9f;
  -webkit-box-shadow: 0px 0px 4px 2px #40fe9f;
}

.admin-form textarea {
  font-size: 20px;
  padding: 10px;
  margin-top: .5rem;
  transition: 250ms ease-in-out;
}

.admin-form textarea:focus {
  box-shadow: 0px 0px 4px 2px #40fe9f;
  -webkit-box-shadow: 0px 0px 4px 2px #40fe9f;
}

.admin-form button {
  border: none;
  position: relative;
  z-index: 0;
  width: 150px;
  height: 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  outline: none;
  color: #fff;
  background-color: #102a3c;
  border: 4px solid #40fe9f;
  margin: 3rem auto 1rem auto;
  cursor: pointer;
  letter-spacing: 3px;
  transition: 250ms ease-in-out;
}

.admin-form button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.admin-form button:active {
    color: #000
}

.admin-form button:active:after {
    background: transparent;
}

.admin-form button:hover:before {
    opacity: 1;
}

.admin-form button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
.resources {
    width: 100%;
    height: auto;
    background-color: #102a3c;
    padding: 4rem 10px;
    overflow: hidden;
  }

/* resources Paralax Image */
.resources .resources-bg {
  width: 100%;
  height: 60vh;
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(/static/media/events-bg.e210ae9a.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.resources .resources-bg img {
  width: 400px;
  height: auto;
}

.resources .resources-bg h2 {
  font-size: 34px;
  color: #fff;
  margin: 1rem 0 2rem 0;
  border-bottom: 2px solid #40fe9f;
  padding-bottom: 10px;
  text-align: center;
}

  .resources .resources-wrapper {
    max-width: 1280px;
    width: 100%;
    padding-top: 12px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .resources .resources-wrapper h4 {
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 0 auto 1rem auto;
    padding-top: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
}

.main-resources {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .left-align {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5rem;
  }
  
  @media (max-width: 750px) {
    .left-align {
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
  }
  
  .main-resources .card {
    margin: 1rem;
    max-width: 25%;
  }

  .main-resources .card h5 {
    font-size: 22px;
    margin-left: 1rem;
    margin-top: .5rem;
    color: #fff;
  }
  
  .main-resources .card p {
    font-size: 18px;
    margin-left: 1rem;
    color: #fff;
  }

  
  .main-resources .small-name {
    text-align: center;
    margin: .5rem auto 0 auto !important;
  }

  .main-resources .small-description {
    text-align: center;
  }

  .resources .resources-wrapper  button {
    width: 180px;
    height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    outline: none;
    color: #fff;
    background-color: #102a3c;
    border: 4px solid #40fe9f;
    margin: 3rem 0 1rem 0;
    cursor: pointer;
    transition: 250ms ease-in-out;
  }
  
  .resources .resources-wrapper  button:hover {
    transform: scale(1.1);
  }
.merch {
    width: 100%;
    height: auto;
    background-color: #102a3c;
    padding: 4rem 10px;
    overflow: hidden;
  }

/* merch Paralax Image */
.merch .merch-bg {
  width: 100%;
  height: 60vh;
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(/static/media/events-bg.e210ae9a.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.merch .merch-bg img {
  width: 400px;
  height: auto;
}

.merch .merch-bg h2 {
  font-size: 34px;
  color: #fff;
  margin: 1rem 0 2rem 0;
  border-bottom: 2px solid #40fe9f;
  padding-bottom: 10px;
  text-align: center;
}

  .merch .merch-wrapper {
    max-width: 1280px;
    width: 100%;
    padding-top: 12px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .merch .merch-wrapper h4 {
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 0 auto 1rem auto;
    padding-top: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
}

.main-merch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .left-align {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5rem;
  }
  
  @media (max-width: 750px) {
    .left-align {
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
  }
  
  .main-merch .card {
    margin: 1rem;
    max-width: 25%;
    width: auto;
  }

  .main-merch .card h5 {
    font-size: 22px;
    margin-left: 1rem;
    margin-top: .5rem;
    color: #fff;
  }
  
  .main-merch .card p {
    font-size: 18px;
    margin-left: 1rem;
    color: #fff;
  }

  .main-merch .card img {
    max-width: auto;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
  }
  
  @media (max-width: 375px) {
    .main-merch .card img {
      height: 290px;
    }
  }
  
  .main-merch .card .small-img {
    height: 250px;
  }
  
  .main-merch .small-name {
    text-align: center;
    margin: .5rem auto 0 auto !important;
  }

  .main-merch .small-description {
    text-align: center;
  }

  .merch .merch-wrapper  button {
    width: 180px;
    height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    outline: none;
    color: #fff;
    background-color: #102a3c;
    border: 4px solid #40fe9f;
    margin: 3rem 0 1rem 0;
    cursor: pointer;
    transition: 250ms ease-in-out;
  }
  
  .merch .merch-wrapper  button:hover {
    transform: scale(1.1);
  }


