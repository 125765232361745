.hero {
  width: 100%;
  height: 100vh;
  background-image: url(https://ik.imagekit.io/obelussoft/hero_0cbTeyiHy.png?updatedAt=1640108522382);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  overflow: hidden;
}

.hero .hero-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.hero .hero-wrapper h2 {
  color: #fff;
  font-size: 44px;
  font-weight: 800;
}

.hero .hero-wrapper h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}

.hero .arrow-down {
  position: absolute;
  bottom: 5%;
  left: 50%;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.hero .arrow-down:hover {
  color: #40fe9f;
}