@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}
* { padding:0; margin:0; outline:0; }

h1, h2, h3, h4, h5, h6, ul, ol, p {
  margin: 0;
}

input, textarea, select { font-family: Arial, sans-serif; font-size:11px; }
a img { border:0; }
a {text-decoration: none; cursor:pointer; }
.cl { font-size:0; line-height:0; clear:both; display:block; height:0; }

html {
  scroll-behavior: smooth;
}

body {
  padding:0;
  margin:0;
  outline:0;
  font-family: 'Nunito', sans-serif;
}