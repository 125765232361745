.admin-panel {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(../images/events-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 4rem 10px;
  overflow: hidden;
}

.admin-panel .admin-panel-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.admin-panel-wrapper .admin-panel-links {
  display: flex;
  justify-content: space-between;
}

.admin-panel-wrapper .admin-link {
  color: #fff;
  font-size: 20px;
  transition: 250ms ease-in-out;
  border-bottom: 2px solid transparent;
  max-width: 190px;
  width: 100%;
  cursor: pointer;
}

.admin-panel-wrapper .admin-link:hover {
  color: #40fe9f;
  border-bottom: 2px solid #fff;
}

.admin-panel-wrapper .admin-link-short {
  max-width: 75px;
}

.admin-panel-wrapper img {
  margin: 0 auto 2rem auto;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 500px) {
  .admin-panel-wrapper img {
    max-width: 100%;
    object-fit: cover;
  }
}

.admin-panel-wrapper h5 {
  font-size: 40px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (max-width: 436px) {
  .admin-panel-wrapper h5 {
    font-size: 30px;
  }
}

@media (max-width: 352px) {
  .admin-panel-wrapper h5 {
    font-size: 22px;
  }
}

@media (max-width: 284px) {
  .admin-panel-wrapper h5 {
    font-size: 20px;
  }
}

.admin-panel-wrapper .fas {
  margin: 0 20px;
}

.admin-panel-wrapper h6 {
  max-width: 200px;
  width: 100%;
  font-size: 28px;
  padding-bottom: 5px;
  cursor: pointer;
  margin: 2rem 0 1rem 0;
}

@media (max-width: 380px) {
  .admin-panel-wrapper h6 {
    font-size: 22px;
  }
}

@media (max-width: 290px) {
  .admin-panel-wrapper h6 {
    font-size: 20px;
  }
}

.admin-panel-wrapper .active-h6 {
  border-bottom: 2px solid #40fe9f;
}

.admin-panel-wrapper p {
  font-size: 20px;
  margin: .5rem 0;
}

.admin-panel-wrapper .admin-panel-buttons {
  display: flex;
  gap: 20px;
}

/* Admin Form */
.admin-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 250ms ease-in-out;
  display: none;
}

.active-form {
  transition: 250ms ease-in-out;
  display: flex;
}

.admin-form label {
  font-size: 20px;
  margin-top: 1rem;
}

.admin-form input {
  height: 40px;
  font-size: 20px;
  padding: 10px;
  outline: none;
  margin-top: .5rem;
  border: 0;
  transition: 250ms ease-in-out;
}

.admin-form input:focus {
  box-shadow: 0px 0px 4px 2px #40fe9f;
  -webkit-box-shadow: 0px 0px 4px 2px #40fe9f;
}

.admin-form textarea {
  font-size: 20px;
  padding: 10px;
  margin-top: .5rem;
  transition: 250ms ease-in-out;
}

.admin-form textarea:focus {
  box-shadow: 0px 0px 4px 2px #40fe9f;
  -webkit-box-shadow: 0px 0px 4px 2px #40fe9f;
}

.admin-form button {
  border: none;
  position: relative;
  z-index: 0;
  width: 150px;
  height: 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  outline: none;
  color: #fff;
  background-color: #102a3c;
  border: 4px solid #40fe9f;
  margin: 3rem auto 1rem auto;
  cursor: pointer;
  letter-spacing: 3px;
  transition: 250ms ease-in-out;
}

.admin-form button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.admin-form button:active {
    color: #000
}

.admin-form button:active:after {
    background: transparent;
}

.admin-form button:hover:before {
    opacity: 1;
}

.admin-form button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}