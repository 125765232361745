footer {
  width: 100%;
  height: auto;
  overflow: hidden;
}

footer .footer-up {
  background-color: #0b1b27;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding: 2rem 10px;
}

@media (max-width: 960px) {
  footer .footer-up {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

footer .footer-up .footer-div {
  display: flex;
  flex-direction: column;
}

@media (max-width: 960px) {
  footer .footer-up .footer-div {
    margin: 1rem 0;
    justify-content: center;
    align-items: center;
  }
}

.footer-div h6 {
  width: 250px;
  font-size: 26px;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 1rem;
}

@media (max-width: 960px) {
  .footer-div h6 {
    text-align: center;
  }
}

.footer-div img {
  width: 350px;
  height: auto;
  margin-bottom: 1rem;
}

.footer-div p {
  width: 400px;
  color: #fff;
}

@media (max-width: 960px) {
  .footer-div img {
    width: 250px;
    height: auto;
    margin-bottom: 1rem;
  }
  .footer-div p {
    width: 250px;
    color: #fff;
    text-align: justify;
  }
}

.footer-div ul {
  list-style: none;
}

.footer-div ul li {
  margin: 1rem 0;
}

.footer-div ul li a {
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
  transition: 200ms ease-in-out;
}

.footer-div ul li a:hover {
  border-bottom: 2px solid #40fe9f;
}

.footer-div .fab {
  color: #fff;
  font-size: 40px;
  margin: .5rem 0;
  transition: 200ms ease-in-out;
}

.footer-div .fab:hover {
  color: #40fe9f;
}

footer .footer-down {
  width: 100%;
  background-color: #000;
  padding: .5rem 10px;
  overflow: hidden;
}

footer .footer-down p {
  color: #fff;
  text-align: center;
  font-size: 18px;
}

footer .footer-down a {
  color: #fff;
}

footer .footer-down a:hover {
  color: #40fe9f;
}

footer .arrow-top {
  font-size: 30px;
  color: #fff;
  position: fixed;
  bottom: 3%;
  right: 2%;
  background-color: #40fe9f;
  border-radius: 50%;
  padding: .5rem;
  transition: 250ms ease-in-out;
}

footer .arrow-top:hover {
  color: #40fe9f;
  background-color: #fff;
}

footer .show {
  display: block;
}

footer .show {
  display: none;
}