.faq {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #102a3c;
  color: #fff;
}

/* FAQ Paralax Image */
.faq .faq-bg {
  width: 100%;
  height: 60vh;
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../images/events-bg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.faq .faq-bg img {
  width: 400px;
  height: auto;
}

.faq .faq-bg h2 {
  font-size: 34px;
  color: #fff;
  margin: 1rem 0 2rem 0;
  border-bottom: 2px solid #40fe9f;
  padding-bottom: 10px;
  text-align: center;
}

/* FAQ Wrapper */
.faq .faq-wrapper {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 10px;
  overflow: hidden;
}

.faq .faq-wrapper p {
  font-size: 20px;
  text-align: center;
  margin: .5rem 0;
}

.faq-wrapper .faq-details {
  width: 100%;
  margin: 2rem 0;
}

.faq-wrapper .faq-details details {
  width: 100%;
}

.faq-wrapper .faq-details summary {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: #fff;
  color: #333;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}

@media (max-width: 550px) {
  .faq-wrapper .faq-details summary {
    font-size: 1rem;
  }
}

.faq .faq-wrapper .faq-content p {
  text-align: justify;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

@media (max-width: 800px) {
  .faq .faq-wrapper .faq-content p {
    font-size: 16px;
  }
}

.faq .faq-wrapper .faq-content a {
  color: #40fe9f;
  margin: 0 5px;
  border-bottom: 2px solid transparent;
  transition: 250ms ease-in-out;
}

.faq .faq-wrapper .faq-content a:hover {
  border-bottom: 2px solid #40fe9f;
}

.faq-wrapper .faq-details details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;
}

.faq-wrapper .faq-details details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px;
}

.faq-wrapper .faq-details details > summary::-webkit-details-marker {
  display: none;
}

.faq-wrapper .faq-details details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}

.faq .faq-wrapper .faq-details h3 {
  max-width: 500px;
  width: 100%;
  margin: 4rem 0 1rem 0;
  font-size: 24px;
  border-bottom: 2px solid #40fe9f;
  padding-bottom: 5px;
}