header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 1;
  transition: 250ms ease-in-out;
}

.header {
  background-color: #194664;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.8); 
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.8);
}

.header2 {
  background-color: transparent;
}

header .logo {
  width: 220px;
  height: auto;
}

header nav ul {
  list-style: none;
}

header nav ul li {
  display: inline-block;
  margin: 0 1rem;
}

header nav ul li a {
  color: #fff;
  font-size: 20px;
  padding-bottom: 5px;
  border-bottom: 0px solid transparent;
  transition: 100ms ease-in-out;
}

@media(max-width: 900px) {
  header nav ul li a {
    font-size: 18px;
  }
}

header nav ul li a:hover {
  border-bottom: 2px solid #40fe9f;
}

header .social a {
  margin: 0 5px;
}

header .social .fab {
  color: #fff;
  font-size: 32px;
  transition: 250ms ease-in-out;
}

header .social .fab:hover {
  color: #40fe9f;
  font-size: 32px;
}

header .bars {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: none;
}

header nav .nav-social {
  display: none;
}

@media (max-width: 850px) {
  header nav {
    display: none;
  }

  header .social {
    display: none;
  }

  header .bars {
    display: block;
  }
}